import React from "react";
import { Link } from "react-router-dom";

export default function Navigation() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
      <a href="/" className="navbar-brand p-0">
        <h1 className="m-0">ZarxBlog</h1>
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse">
        <span className="fa fa-bars"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav mx-auto py-0">
          <Link to={"/"} className="nav-item nav-link">
            Home
          </Link>
          <Link to={"/about"} className="nav-item nav-link">
            About
          </Link>
          <Link to={"/service"} className="nav-item nav-link">
            Service
          </Link>
          <Link to={"/project"} className="nav-item nav-link">
            Project
          </Link>
          <div className="nav-item dropdown">
            <a
              href="/"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown">
              Pages
            </a>
            <div className="dropdown-menu m-0">
              <Link to={"/team"} className="dropdown-item">
                Team
              </Link>
              <Link to={"/testimonial"} className="dropdown-item">
                Testimonial
              </Link>
            </div>
          </div>
          <Link to={"/contact"} className="nav-item nav-link">
            Contact
          </Link>
        </div>
        <a
          href="/"
          className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block">
          Get Started
        </a>
      </div>
    </nav>
  );
}
