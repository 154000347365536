import React from 'react'
import Navigation from "../components/Navigation";

export default function Team() {
  return (
    <>
        <div className="position-relative p-0">
        <Navigation />
       
        <div className="py-5 bg-primary hero-header">
            <div className="container my-5 py-5 px-lg-5">
                <div className="row g-5 py-5">
                    <div className="col-12 text-center">
                        <h1 className="text-white animated slideInDown">Our Team</h1>
                        <hr className="bg-white mx-auto mt-0" style={{width: "90px"}} />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Our Team</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    <div className="py-5">
        <div className="container py-5 px-lg-5">
            <div className="wow fadeInUp" data-wow-delay="0.1s">
                <p className="section-title text-secondary justify-content-center"><span></span>Our Team<span></span></p>
                <h1 className="text-center mb-5">Our Team Members</h1>
            </div>
            <div className="row g-4">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="team-item bg-light rounded">
                        <div className="text-center border-bottom p-4">
                            <img className="img-fluid rounded-circle mb-4" src="img/team-1.jpg" alt="" />
                            <h5>John Doe</h5>
                            <span>CEO & Founder</span>
                        </div>
                        <div className="d-flex justify-content-center p-4">
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded">
                        <div className="text-center border-bottom p-4">
                            <img className="img-fluid rounded-circle mb-4" src="img/team-2.jpg" alt="" />
                            <h5>Jessica Brown</h5>
                            <span>Web Designer</span>
                        </div>
                        <div className="d-flex justify-content-center p-4">
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item bg-light rounded">
                        <div className="text-center border-bottom p-4">
                            <img className="img-fluid rounded-circle mb-4" src="img/team-3.jpg" alt="" />
                            <h5>Tony Johnson</h5>
                            <span>SEO Expert</span>
                        </div>
                        <div className="d-flex justify-content-center p-4">
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="team-item bg-light rounded">
                        <div className="text-center border-bottom p-4">
                            <img className="img-fluid rounded-circle mb-4" src="img/team-2.jpg" alt="" />
                            <h5>John Doe</h5>
                            <span>CEO & Founder</span>
                        </div>
                        <div className="d-flex justify-content-center p-4">
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="team-item bg-light rounded">
                        <div className="text-center border-bottom p-4">
                            <img className="img-fluid rounded-circle mb-4" src="img/team-3.jpg" alt="" />
                            <h5>Jessica Brown</h5>
                            <span>Web Designer</span>
                        </div>
                        <div className="d-flex justify-content-center p-4">
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item bg-light rounded">
                        <div className="text-center border-bottom p-4">
                            <img className="img-fluid rounded-circle mb-4" src="img/team-1.jpg" alt="" />
                            <h5>Tony Johnson</h5>
                            <span>SEO Expert</span>
                        </div>
                        <div className="d-flex justify-content-center p-4">
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-instagram"></i></a>
                            <a className="btn btn-square mx-1" href=""><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
