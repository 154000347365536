import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import HomePage from './pages/HomePage'
import About from './pages/About'
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Service from "./pages/Service";
import Project from "./pages/Project";
import Contact from "./pages/Contact";
import Team from "./pages/Team";
import Testimonial from "./pages/Testimonial";



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/service",
        element: <Service />
      },
      {
        path: "/project",
        element: <Project />
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/team",
        element: <Team />
      },
      {
        path: "/testimonial",
        element: <Testimonial />
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
